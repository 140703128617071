import React from "react";

export default function FeedbackNumberInput(props) {
    
    return  <label className="d-block mt-4" htmlFor="">{props.label}
                <div className="input-group input-group-sm mb-3"> 
                    <input type="number" className="form-control" onWheel={(e) => e.target.blur()} value={props.value} onChange={(e) => props.fieldUpdate(props.keyName , e.target.value)} disabled={props.disabled} required/>                          
                    <div className="invalid-feedback">נא למלא את השדה כראוי</div>
                </div>
            </label>  
}