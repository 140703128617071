import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './Header.css'
import { Link, useLocation } from "react-router-dom";
import { exportMainTable, setFleg } from "../../store/YamatReducer";
import LinkIcon from "../../assets/svg/linkIcon";
import ExportIcon from "../../assets/svg/export";
import ProfileIcon from "../../assets/svg/profile";
import logoIcon from "../../assets/images/logo.png";
import LogoutIcon from "../../assets/svg/logout";
import disconnect from "../../pages/Login/disconnect";

export default function Header(props) {

    const store = useSelector(state => state.reducer)
    const location = useLocation()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)
    const admin = store.userDetails.isAdmin
    const data = props.mainDataObj?.HeaderMenuItems
    const textArrayIndex = props.mainDataObj?.PopupPages?.findIndex(x => x.PopupPageTypeDictionary.Value === "MunicipalitiesContactLink")
    const textArray = textArrayIndex >= 0 ? props.mainDataObj?.PopupPages[textArrayIndex] : false

    /**
     *  when clicking outside of the contact popup -> close popup
     */
    const useClickOutside = (handler) => {
      const domNode = useRef();
      useEffect(() => {
          const eventHandler = (event) => {
              if (domNode.current && !domNode.current.contains(event.target)) {
                  handler();
              }
          }
          document.addEventListener("mousedown", eventHandler);
          return () => {
              document.removeEventListener('mousedown', eventHandler);
          }
      });
      return domNode;
  }

  const contact_popup = useClickOutside(() => {
      setOpen(false);
  })

    return  <header className="header_comp">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="header_logo" href={`/${store.locationIdent}/projects`}>
                    <img src={logoIcon} alt="יום מעשים טובים"/>
                </a>
                {store.fleg.connected && <div className="collapse navbar-collapse" id="main_navbar">
                  <div className="navbar-nav">
                    {
                      data?.map((item, index) => {
                        if(item.MenuItemHeaderID === 1){
                          return <Link key={index} className={`nav-item nav-link px-3 ${location.pathname === '/projects' ? 'active' : ''}`} to={`/${store.locationIdent}/projects`}>{item.Title || 'רשימת פרויקטים'}</Link>
                        }else if(item.MenuItemHeaderID === 3){
                          return <a key={index} onClick={() => dispatch(setFleg({key: 'welcomPopup', value: false}))} className="pointer nav-item nav-link px-3">{item.Title || 'עקרונות מנחים'}</a>
                        }
                      })
                    }
                    
                    {/* <Link className={`nav-item nav-link px-3 ${location.pathname === '/archive' ? 'active' : ''}`} to="archive">{data ? data[1].Title : 'היסטוריה'}</Link> */}
                    
                  </div>
                </div>}

                {store.fleg.connected && <div ref={contact_popup} className={`collapse navbar-collapse d-flex justify-content-end`}>
                  <div className="navbar-nav relative d-flex align-items-center">
                    {open && textArray && <div className="contact_popup p-4">
                                <p className="primary_color popup_title mb-1">{textArray.Title}</p>
                                <p className="mb-4" dangerouslySetInnerHTML={{__html: textArray.RawHTML}}></p>
                                <div className="input-group mb-1">           
                                  <input type="text" className="form-control border-0" placeholder="" aria-label="Example text with button addon" value={window.location.host + '/' + (location.pathname.split("/")[1])} aria-describedby="button-addon1" readOnly/>
                                  <button onClick={() => navigator.clipboard.writeText(window.location.host + '/' + (location.pathname.split("/")[1]))} className="btn btn-primary popup_title border-0 outline-0" type="button" id="button-addon1">{textArray.ButtonText}</button>
                                </div>
                            </div>}
                    <div className="d-flex">
                    {
                      data?.map((item, index) => {
                        if(item.MenuItemHeaderID === 4){
                          return <a key={index} onClick={() => setOpen(!open)} className={`pointer nav-item nav-link px-3`}><span className="me-1"><LinkIcon/></span>{item.Title || 'לינק לאנשי הקשר'}</a>
                        }else if(item.MenuItemHeaderID === 5){
                          return <Link key={index} onClick={() => dispatch(exportMainTable())} className={`nav-item nav-link px-3 me-5 ms-0`}><span className="me-1"><ExportIcon/></span>{item.Title || 'ייצוא לאקסל'}</Link>
                        }
                      })
                    }        
                    </div>
                    

                    {admin && <div className="d-flex justify-content-end align-items-center me-5 relative" onMouseEnter={() => setLogoutPopup(true)} onMouseLeave={() => setLogoutPopup(false)}>
                      <p className="nav-link mb-0">{store.userDetails.name}</p>
                      <ProfileIcon/>
                      {logoutPopup && <div onClick={() => disconnect(store.locationIdent)} className="pointer disconnect_popup disconnect_shadow position-absolute bg-body text-disabled d-flex justify-content-center align-items-center">
                          התנתק
                          <span className="me-0 ms-2"><LogoutIcon disabled={true}/></span>
                      </div>}
                    </div>}

                    {!admin && <div className="disconnect_reg d-flex justify-content-end align-items-center me-0 ms-5">
                      <div onClick={() => disconnect(store.locationIdent)} className="pointer disconnect_popup bg-body d-flex justify-content-end align-items-center">
                          התנתק
                          <span className="me-0 ms-2"><LogoutIcon disabled={false}/></span> 
                      </div>
                    </div>}

                  </div>
                </div>}

              </nav>
            </header>  
}