import { React, useEffect, useRef, useState } from "react";
import { addProject, apiFormValidation, editProject, getMunicForm, getProjectInfo } from "../../utils/apiUtils";
import './ActionForm.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ContactSection from "./ContactSection";
import ProjectSection from "./ProjectSection";
import PartnerSection from "./PartnerSection";
import { useDispatch, useSelector } from "react-redux";
import { setFormExtraVals, setFormVals } from "../../store/YamatReducer";
import SuccessFormSection from "./SuccessFormSection";
import { useParams } from "react-router-dom";
import AlertPopup from "../../components/AlertPopup/AlertPopup";
import { stepsDic } from "../../utils/externalFunc";



export default function ActionForm(props){

    
    const store = useSelector(state => state.reducer)
    const [formPageData, setFormPageData] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [steps, setSteps] = useState([])
    const [activeStep, setActiveStep] = useState(store.formExtraVals.activeStep)
    const [sectionsArray, setSectionsArray] = useState(["ContactSection_form", "ProjectSection_form", "PartnerSection_form"])
    const [formValidation, setFormValidation] = useState({ContactSection_form: "start", ProjectSection_form: "start", PartnerSection_form: "start"})
    const [errorsObjReady, setErrorsObjReady] = useState({})
    const params = useParams()
    const [editMode, setEditMode] = useState(params.id)
    const [errorModalShow, setErrorModalShow] = useState(false)
    const [loadProjectAlertModalShow, setLoadProjectAlertModalShow] = useState(false)
    const [formValsLocal, setFormValsLocal] = useState(store.formVals)
    const [successMsg, setSuccessMsg] = useState("")
    const [saveErrorMsg, setSaveErrorMsg] = useState("")
    const [loadErrorMsg, setLoadErrorMsg] = useState("")
    const dispatch = useDispatch()

    const getForm = async () => {
        getMunicForm(store.locationIdent).then(res => {
            setFormPageData(res.data)
            let stepsArr = []
            res.data.FormSteps.map(item => {
                stepsArr.push(item.StepLabel)
            })
            setSteps(stepsArr)
        })
        .catch(error => {
            console.log(error)
        })
    }

    const handleNext = () => {
        activeStep < stepsDic.SuccessFormSection && checkStep()
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        window.scrollTo(0, 0)
    }

    let finalMount = useRef(true);
    useEffect(() => {
        if (finalMount.current && store.locationIdent) {
            finalMount.current = false;
            editMode && setActiveStep(stepsDic.ContactSection_form)
            getForm()
            editMode && getProject()
        }
    }, [store.locationIdent])

    const getProject = () => {
        getProjectInfo(editMode, store.locationIdent).then(res => {
            setFormValsLocal(res.data)
        }).catch(error => {
            setLoadProjectAlertModalShow(true)
            setLoadErrorMsg(error.response.data.Message)
        })
    }

    const sendFormEditAndCreate = async () => {
        let obj = {...store.formVals}
        if(!store.formExtraVals.isPartner)  obj = {...obj, ProjectPartners: []}
        if(!store.formExtraVals.isBudget)  obj = {...obj, ProjectBudgets: []}
        if(!obj.ProjectDetails.IsOrganization)  obj = {...obj, ProjectDetails: {...obj.ProjectDetails, OrganizationName: ""}}
        return editMode ? await editProject(obj, store.locationIdent) : await addProject(obj, store.locationIdent) 
    }

    const sendForm = () => {
        // if validation ok, send form or step up
        if(activeStep + 1 === stepsDic.SuccessFormSection){
            setFormLoader(true)
            sendFormEditAndCreate().then(res => {
                if(res.data.success){
                    setSuccessMsg(res.data.success_message)
                    setActiveStep(stepsDic.SuccessFormSection)
                    props.getMainObjData()
                }else{
                    setSaveErrorMsg("שגיאה בשמירת הטופס")
                    setErrorModalShow(true)
                }
            }).catch(error => {
                setSaveErrorMsg(error.response.data.Message)
                setErrorModalShow(true)
            }).finally(() => setFormLoader(false))
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            window.scrollTo(0, 0)
        }
    }

    const checkStep = () => {
        apiFormValidation(store).then(res => {
            return  bootstrapFormValidation() && 
                    manageErrors(res.data.errors) && 
                    (activeStep + 1) < 4 && 
                    sendForm()
        }).catch(error => {
            console.error(error)
        })
    }

    const bootstrapFormValidation = () => {
        let form = document.getElementById(sectionsArray[activeStep])
        setFormValidation({...formValidation, [sectionsArray[activeStep]]: form.checkValidity()})
        return form.checkValidity()
    }

    const manageErrors = (errArray) => {
        
        let errors = {}
        let stepArray = ["Contact","ProjectDetails"]
        if(errArray){
            if(errArray.length > 0){
                errArray.map(item => {
                    if(item.Key.includes(stepArray[activeStep])){
                        let key = item.Key.split(".")
                        key = key.length > 0 ? key[key.length -1] : key
                        errors[key] = item.Value
                    }
                })
            }
            console.log(errors)
        }
        setErrorsObjReady(errors)
        return Object.keys(errors).length === 0
    }

    useEffect(() => {
        dispatch(setFormExtraVals({...store.formExtraVals, activeStep: activeStep}))
    }, [activeStep])

    useEffect(() => {
        dispatch(setFormVals(formValsLocal))
    }, [formValsLocal])

    const setFormState = (value, object_path) => {
        setFormValsLocal({...formValsLocal, [object_path]: value})
    }

    return  <div className="container ActionForm align-items-center d-flex flex-column">
                {activeStep < 3 && <h1 className="text-center f-bold mb-5">{editMode ? formPageData.TitleEdit : formPageData.Title}</h1>}
                
                    {activeStep < 3 && <div className="stepper_con">
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps?.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </div>}

                    {activeStep === stepsDic.ContactSection_form && formPageData && <ContactSection formValsLocal={formValsLocal} errorsObjReady={errorsObjReady} validation={formValidation[sectionsArray[activeStep]]} setFormState={setFormState} stepInfo={formPageData.FormSteps[0]}/>}
                    {activeStep === stepsDic.ProjectSection_form && formPageData && <ProjectSection formValsLocal={formValsLocal} errorsObjReady={errorsObjReady} validation={formValidation[sectionsArray[activeStep]]} setFormState={setFormState} stepInfo={formPageData.FormSteps[1]}/>}
                    {activeStep === stepsDic.PartnerSection_form && formPageData && <PartnerSection formValsLocal={formValsLocal} editMode={editMode} errorsObjReady={errorsObjReady} validation={formValidation[sectionsArray[activeStep]]} setFormState={setFormState} stepInfo={formPageData.FormSteps[2]}/>}
                    {activeStep === stepsDic.SuccessFormSection && formPageData && <SuccessFormSection successMsg={successMsg}/>}


                    {activeStep < stepsDic.SuccessFormSection && <div className="d-flex justify-content-center mt-5">
                        <button color="inherit" disabled={activeStep === stepsDic.ContactSection_form} onClick={handleBack} className="btn-primary btn ms-0 me-1 f-bold">לקודם</button>
                        <button onClick={handleNext} className="btn-primary btn f-bold">
                            {/* last stage -> PartnerSection */}
                            {activeStep === stepsDic.PartnerSection_form ? 'סיום' : 'הבא'}
                            {formLoader && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                        </button>
                        <AlertPopup textLine1={saveErrorMsg} textLine2={``} setModalShow={setErrorModalShow} modalShow={errorModalShow} tryAgainFunc={handleNext}/>
                        <AlertPopup textLine1={loadErrorMsg || 'תקלה בטעינת פרויקט'} textLine2={``} setModalShow={setLoadProjectAlertModalShow} modalShow={loadProjectAlertModalShow} tryAgainFunc={false}/>
                    </div>}         
            </div>
}