import React from "react";
import './Footer.css'
import LogoIcon from "../../assets/svg/logo";

export default function Footer(props) {

  const footerText = props.mainDataObj?.Footer?.RawHTML
    
    return  <footer className="Footer d-flex align-items-center py-3 px-4 w-100 mt-5">
              <LogoIcon/>
              <span className="ms-3" dangerouslySetInnerHTML={{__html: footerText}}></span>
            </footer>  
}