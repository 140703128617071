import React from "react";

export default function FeedbackTextArea(props) {
    
    return  <> 
                <label htmlFor="proccess" className="form-label mb-2 d-block mt-4">{props.label}
                    <div className="input-group input-group-sm mb-3"> 
                        <textarea
                            value={props.value}
                            required
                            rows={6}
                            disabled={props.disabled}
                            onChange={(e) => {props.setProccessDescription(props.keyName, e.target.value)}} 
                            className="form-control" id="proccess"></textarea>                             
                        <div className="invalid-feedback">נא למלא את השדה כראוי</div>
                    </div>
                </label>
            </>
}