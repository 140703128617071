export const setLocalStorage = (step, item) => {
  localStorage.setItem(step, JSON.stringify(item))
}

export const getLocalStoragKey = (localStoreName, key) => {
  const storage = localStorage.getItem(localStoreName)
  const localStorageState = storage && storage!=='undefined' ? JSON.parse(storage) : undefined;
  return localStorageState && key ? localStorageState[key] : localStorageState;
}

export const ClearLocalStorage = () => {
  localStorage.clear();
}