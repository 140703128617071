export default function LogoutIcon(props) {

    const color = props.disabled ? "#BABABA" : "#787878"
    return  <><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <mask id="g91ovlujra" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                    <path d="M18 0H0v18h18V0z" fill="#fff"/>
                </mask>
                <g mask="url(#g91ovlujra)">
                    <mask id="kci11bqdab" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                        <path d="M0 0h18v18H0V0z" fill="#fff"/>
                    </mask>
                    <g mask="url(#kci11bqdab)" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M11.988 13.781v1.406a2.11 2.11 0 0 1-2.11 2.11H2.813a2.11 2.11 0 0 1-2.109-2.11V2.813a2.11 2.11 0 0 1 2.11-2.11h7.066a2.11 2.11 0 0 1 2.11 2.11v1.406M17.121 9.035H7.594M15.414 11.355l1.575-1.574a1.055 1.055 0 0 0 0-1.492l-1.575-1.574"/>
                    </g>
                </g>
            </svg>
            </>
}