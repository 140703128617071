import { React, useEffect, useState } from "react";
import './ActionForm.css';
import { CustomSwitchBox, extractKeyName, fieldTypeDic, sortArray } from "../../utils/externalFunc";
import PlusCircleIcon from "../../assets/svg/plusCircle";
import CloseCircleIcon from "../../assets/svg/closeCircle";
import { useDispatch, useSelector } from "react-redux";
import { setFormExtraVals, setFormVals } from "../../store/YamatReducer";

export default function PartnerSection(props){

    const store = useSelector(state => state.reducer)
    const [isPartner, setIsPartner] = useState(props.editMode ? true : store.formExtraVals.isPartner)
    const [isBudget, setIsBudget] = useState(props.editMode ? true : store.formExtraVals.isBudget)
    const intialPartnerObj = {
        "ProjectPartnerId": 1,
        "ProjectPartnerTypeId": 0,
        "ProjectPartnerComment": "",
    }
    const intialBudgetObj = {
        "ProjectBudgetID": 1,
        "BudgetDescription": "",
        "MunicipalityValueDescription": "",
        "MunicipalityAmount": "",
        "RuachtovaAmount": ""
      }

    const [projectBudgets, setProjectBudgets] = useState(props.formValsLocal.ProjectBudgets ? typeof(props.formValsLocal.ProjectBudgets) === 'object' && props.formValsLocal.ProjectBudgets.length === 0 ? [intialBudgetObj] : props.formValsLocal.ProjectBudgets : [intialBudgetObj])
    const [projectPartners, setProjectPartners] = useState(props.formValsLocal.ProjectPartners ? typeof(props.formValsLocal.ProjectPartners) === 'object' && props.formValsLocal.ProjectPartners.length === 0 ? [intialPartnerObj] : props.formValsLocal.ProjectPartners : [intialPartnerObj])
    
    const [mainArray, setMainArray] = useState(props.stepInfo.FormFields)
    const dispatch = useDispatch()
    var switchIds = []

    useEffect(() => {
        props.setFormState(projectPartners, "ProjectPartners")
    }, [projectPartners])

    useEffect(() => {
        props.setFormState(projectBudgets, "ProjectBudgets")
    }, [projectBudgets])

    useEffect(() => {
        if(props.stepInfo.FormFields){
            let arr = sortArray(props.stepInfo.FormFields)
            setMainArray(arr)
            console.log('setFormExtraVals to true all')
            dispatch(setFormExtraVals({...store.formExtraVals, isPartner: true, isBudget: true }))
        }
    }, [store.formExtraVals.activeStep])

    const updateBlockArray = (blockName, index, key, value) => {
        let arr = blockName === "projectPartners" ? [...projectPartners] : [...projectBudgets]
        let obj = {...arr[index]}
        obj[key] = value
        arr[index] = obj
        blockName === "projectPartners" ? setProjectPartners(arr) : setProjectBudgets(arr)
    }

    const addBlock = (blockName) => {
        if(blockName === "projectPartners"){
            setProjectPartners([...projectPartners, {...intialPartnerObj, ProjectPartnerId: projectPartners.length + 1 }])
        }else if(blockName === "projectBudgets"){
            setProjectBudgets([...projectBudgets, {...intialBudgetObj, ProjectBudgetID: projectBudgets.length + 1 }])
        }
        
    }

    const removeBlock = (blockName, index) => {
        let arr = blockName === "projectPartners" ? [...projectPartners] : [...projectBudgets]
        arr.splice(index, 1)
        blockName === "projectPartners" ? setProjectPartners(arr) : setProjectBudgets(arr)
    }

    const updateFormExtraVals = (key, value) => {
        key === "isPartner" && setIsPartner(value)
        key === "isBudget" && setIsBudget(value)
        dispatch(setFormExtraVals({...store.formExtraVals, [key]: value}))
    }


    return  <>  <div className="PartnerSection my-5">
                    <h2 className="font-xl text-center primary_color f-bold">{props.stepInfo.Title}</h2>
                </div>
            <form action="" id="PartnerSection_form" className={`PartnerSection_form needs-validation ${props.validation === "start" ? "" : "was-validated"}`} noValidate> {/** was-validated */}

                <div className="row gx-5">
                    <div className="col-6">
                    {
                        mainArray?.map((item, index) => {
                            let formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false
                            
                            if(formFieldDetails && 'switch_ids' in formFieldDetails) {switchIds = formFieldDetails.switch_ids}
                            if(formFieldDetails.identifier === 'IsPartners'){
                                return  <div key={`check_${item.FormFieldID}`}>
                                            <div className={'form-group d-flex align-items-center mb-3'}>
                                                <label htmlFor={formFieldDetails.identifier}>{item.Title}</label>                             
                                                {<CustomSwitchBox id={formFieldDetails.identifier} checked={isPartner} onChange={(e) => {updateFormExtraVals("isPartner", e.target.checked); console.log("update")}}/>}
                                            </div>
                                            <h3 className="font-f-title mb-3">מי השותפים? (עמותות, חברות)</h3>
                                        </div>
                            }else if(switchIds && switchIds.includes(item.FormFieldID) && ((formFieldDetails.identifier === "ProjectPartnerTypeId") )){                              
                                      return <div key={item.FormFieldID}> 
                                                {projectPartners.map((subItem, inx) => {
                                                    let inputArray = mainArray.filter(x => switchIds.includes(x.FormFieldID))
                                                    return <div key={inx} className={`relative partnerBlock mb-4 py-3 px-4 ${isPartner ? 'bg-body ' : 'disabled_back '}`}>
                                                            {inx > 0 && <div className='position-absolute close_circle_icon gray_block pointer' onClick={() => isPartner ? removeBlock("projectPartners", inx) : null} ><CloseCircleIcon /></div>}
                                                        {inputArray?.map(switchItem => {
                                                            let innerFormFieldDetails = switchItem.FormFieldDetails ? JSON.parse(switchItem.FormFieldDetails) : false
                                                            let keyName = extractKeyName(innerFormFieldDetails.object_path)
                                                            return <label key={`switch_${switchItem.FormFieldID}`} htmlFor={keyName} className="form-label mb-2 d-block">
                                                                        <span>{switchItem.IsRequired ? "*" : ""}{switchItem.Title}</span>
                                                                        <div className="input-group input-group-sm mb-3">
                                                                            {fieldTypeDic[switchItem.FormFieldType] === 'SelectSingle' &&
                                                                                <select id={keyName} value={subItem[keyName]} onChange={(e) => {updateBlockArray("projectPartners", inx, keyName, Number(e.target.value))}} disabled={!isPartner} className="form-control border" required>
                                                                                    <option value=""></option>
                                                                                    {innerFormFieldDetails.select_values.map(opt => {
                                                                                        return <option key={opt.id} value={opt.id}>{opt.title}</option>
                                                                                    })}
                                                                                </select>
                                                                            }
                                                                            {fieldTypeDic[switchItem.FormFieldType] === 'textarea' &&
                                                                                <textarea 
                                                                                    id={keyName} 
                                                                                    rows={6} 
                                                                                    disabled={!isPartner} 
                                                                                    onChange={(e) => {updateBlockArray("projectPartners", inx, keyName, e.target.value)}} 
                                                                                    className="form-control border" 
                                                                                    value={subItem[keyName]} 
                                                                                    required
                                                                                ></textarea> 
                                                                            }
                                                                            <div className="invalid-feedback">{`יש למלא ${switchItem.Title}`}</div>
                                                                        </div>
                                                                    </label>
                                                        })}
                                                    </div>
                                                })}
                                            </div>
                                       
                            }
                        })
                    }
                        <div className="d-flex align-items-center mt-3">
                            <span className="me-2 ms-0 pointer" onClick={() => isPartner ? addBlock("projectPartners") : ''}><PlusCircleIcon active={isPartner}/></span>
                            <span className="font-m f-bold">שותף חדש</span>
                        </div>
                    </div>
                    <div className="col-6">
                    {
                        mainArray?.map((item, index) => {
                            let formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false                          
                            if(formFieldDetails && 'switch_ids' in formFieldDetails) {switchIds = formFieldDetails.switch_ids}

                            if(formFieldDetails.identifier === 'IsBudget'){
                                return  <div key={`check_${item.FormFieldID}`}>
                                            <div className={'form-group d-flex align-items-center mb-3'}>
                                                <label htmlFor={formFieldDetails.identifier}>{item.Title}</label>                             
                                                {<CustomSwitchBox id={formFieldDetails.identifier} checked={isBudget} onChange={(e) => {updateFormExtraVals("isBudget", e.target.checked)}}/>}
                                            </div>
                                            <h3 className="font-f-title mb-3">מי השותפים? (עמותות, חברות)</h3>
                                        </div>
                            }else if(switchIds && switchIds.includes(item.FormFieldID) && (formFieldDetails.identifier === "BudgetDescription")){                              
                                return  <div key={item.FormFieldID}> 
                                            {projectBudgets.map((subItem, inx) => {
                                                let inputArray = mainArray.filter(x => switchIds.includes(x.FormFieldID))
                                                {inx > 0 && <div className='position-absolute close_circle_icon gray_block pointer' onClick={() => isPartner ? removeBlock("projectPartners", inx) : null} ><CloseCircleIcon /></div>}
                                                return <div key={inx} className={`relative budgetBlock mb-4 py-3 px-4 ${isBudget ? 'bg-body' : 'disabled_back'}`}>
                                                        {inx > 0 && <div className='position-absolute close_circle_icon gray_block pointer' onClick={() => isBudget ? removeBlock("projectBudgets", inx) : null} ><CloseCircleIcon /></div>}
                                                        {inputArray?.map(switchItem => {
                                                            let innerFormFieldDetails = switchItem.FormFieldDetails ? JSON.parse(switchItem.FormFieldDetails) : false
                                                            let keyName = extractKeyName(innerFormFieldDetails.object_path)
                                                            return <label key={`switch_${switchItem.FormFieldID}`} htmlFor={keyName} className="form-label mb-2 d-block">
                                                                        <span>{switchItem.IsRequired ? "*" : ""}{switchItem.Title}</span>
                                                                        <div className="input-group input-group-sm mb-3">
                                                                            <input 
                                                                                onChange={(e) => {updateBlockArray("projectBudgets", inx, keyName, fieldTypeDic[switchItem.FormFieldType] === "number" ? Number(e.target.value) : e.target.value)}} 
                                                                                value={subItem[keyName]} 
                                                                                type={fieldTypeDic[switchItem.FormFieldType]} 
                                                                                disabled={!isBudget} className="form-control border" 
                                                                                id={keyName} 
                                                                                required
                                                                                onWheel={(e) => e.target.blur()}
                                                                            />
                                                                            <div className="invalid-feedback">{`יש למלא ${switchItem.Title}`}</div>
                                                                        </div>
                                                                    </label>
                                                        })}
                                                        </div>
                                            })}
                                        </div>
                            }
                        })
                    }
                        <div className="d-flex align-items-center mt-3">
                            <span className="me-2 ms-0 pointer" onClick={() => isBudget ? addBlock("projectBudgets") : ''}><PlusCircleIcon active={isBudget}/></span>
                            <span className="font-m f-bold">סיוע תקציבי לייעוד נוסף בפרויקט</span>
                        </div>
                    </div>
                    
                </div>

            </form></>
}
