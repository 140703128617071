export default function PlusIcon() {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clipPath="url(#iwkanognda)">
                    <path d="M1.429 9.429h4.857c.158 0 .285.127.285.285v4.857a1.429 1.429 0 0 0 2.858 0V9.714c0-.158.127-.285.285-.285h4.857a1.429 1.429 0 0 0 0-2.858H9.714a.286.286 0 0 1-.285-.285V1.429a1.429 1.429 0 0 0-2.858 0v4.857a.286.286 0 0 1-.285.285H1.429a1.429 1.429 0 0 0 0 2.858z" fill="#fff"/>
                </g>
                <defs>
                    <clipPath id="iwkanognda">
                        <path fill="#fff" transform="rotate(180 8 8)" d="M0 0h16v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
}