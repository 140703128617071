import React, { useEffect, useRef, useState } from 'react';
import "./Login.css"
import { authenticateCode, getLoginForm, sendPhoneNumber } from '../../utils/apiUtils';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setFleg, setUserDetails } from '../../store/YamatReducer';
import { isDesktop, isMobile } from 'react-device-detect';

export default function Login(props){

    const store = useSelector(state => state.reducer)
    const [modalShow, setModalShow] = useState(false)
    const [loginPhone, setLoginPhone] = useState()
    const [disabledLoginButton, setDisabledLoginButton] = useState(true)
    const [codeMode, setCodeMode] = useState(false)
    const [popLoader, setPopLoader] = useState(false)
    const [loginCode, setloginCode] = useState()
    const [errorMsg, setErrorMsg] = useState(false)
    const [loginErrorMsg, setLoginErrorMsg] = useState(false)
    const [loginInfo, setLoginInfo] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handlePhoneNumber = () => {
        setPopLoader(true)
        setErrorMsg(false)
        sendPhoneNumber(loginPhone, store.locationIdent).then(res => {
            setPopLoader(false)
            setCodeMode(true)
        }).catch(error => {
            setPopLoader(false)
            setErrorMsg(error.response.data?.Message)
        })
    }

    const login = () => {
        setPopLoader(true)
        setErrorMsg(false)
        loginCode && authenticateCode(loginPhone, loginCode).then(res => {
            setPopLoader(false)
            let token = res.data.access_token
            if(token){
                dispatch(setUserDetails({...store.userDetails, isAdmin: res.data.isAdmin === "True", name: res.data.name, phone: res.data.userPhone}))  
                dispatch(setFleg({key: 'connected', value: true}))
                props.manageToken(token)
                const cookies = new Cookies();
                cookies.set("ymt_stk", token, { path: '/', maxAge: res.data.expires_in });
                setModalShow(false)
                navigate(`/${store.locationIdent}/projects`)
            }
        }).catch(error => {
            setPopLoader(false)
            setErrorMsg(error?.response?.data.error_description)
        })
    }

    const getLogin = () => {     
        getLoginForm(store.locationIdent).then((res) => {
            setLoginInfo(res.data)
        }).catch(error => {setLoginErrorMsg(error.response.data.Message)})      
    }

    let finalMount = useRef(true);
    useEffect(() => {
        if(finalMount.current && store.locationIdent){
            finalMount.current = false
            isDesktop && getLogin()
            isDesktop && setModalShow(true)
        }
    }, [store.locationIdent])

    const doAction = (e) => {
        e.preventDefault()
        codeMode ? login() : handlePhoneNumber()
    }

    return (
        <>
            <div style={{minHeight: '74vh'}}></div>
            {isDesktop && <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
            >
                <div className='row f-reg login_popup'>
                    <div className="col-4 d-flex justify-content-center align-items-center purple_back">
                        {loginInfo && <p className='text-light text-center mb-0'>{loginInfo.Title}</p>}

                    </div>
                    <div className="col-8 bg-body">
                        {!codeMode && loginInfo && <form onSubmit={doAction} className="w-100 h-100 p-5 d-flex justify-content-between flex-column">
                            <div>
                                <p className="f-bold mb-4">{loginInfo.SubTitle}</p>
                                {errorMsg && <div className='text-danger'>{errorMsg}</div>}
                                <label htmlFor='login' className="form-label mb-1">{loginInfo.FormFields && (loginInfo.FormFields[0]?.Title || "מספר טלפון")}</label>
                                <div className="input-group input-group-sm mb-3"> 
                                    <input autoFocus type="tel" className="form-control border border-1" 
                                    onChange={(e) => {
                                        setLoginPhone(e.target.value)
                                        setDisabledLoginButton(!e.target.value)
                                    }} id="login" required/>
                                    <div className="invalid-feedback">נא למלא את השדה כראוי</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-3 relative">
                                <button type="button" className="btn btn-primary f-bold d-flex justify-content-center align-items-center" onClick={handlePhoneNumber} disabled={disabledLoginButton}>
                                    {loginInfo.FormFields && (loginInfo.FormFields[1]?.Title || "שלחו לי סיסמה חד פעמית")}  
                                    {popLoader && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                                </button>                                               
                            </div>
                        </form>}
                        {codeMode && loginInfo && <form onSubmit={doAction} className={`h-100 w-100 p-5 d-flex flex-column justify-content-${errorMsg ? 'between' : 'center'}`}>
                                {errorMsg && <div className='text-danger text-center mt-5'>{errorMsg}</div>} 
                                <div>
                                    <label htmlFor='login' className="form-label mb-1">הזינו את הקוד שקיבלתם</label>  
                                    <div className="input-group mb-1">                    
                                        <input type="text" autoFocus onChange={(e) => setloginCode(e.target.value)} className="h-26 form-control border border-1 text-color-regular" aria-describedby="button-addon1"/>
                                        <button onClick={login} disabled={!loginCode} className="p-0 h-26 btn btn-primary popup_title border-0 outline-0 w-43 f-bold d-flex align-items-center justify-content-center" type="button" id="button-addon1">
                                            שליחה
                                            {popLoader && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                                        </button>
                                    </div>
                                </div>
                                {errorMsg && <div className='text-end'><a className='pointer' onClick={handlePhoneNumber}> שלחו לי קוד חדש </a></div>}
                        </form>}
                        {!loginInfo && !loginErrorMsg && <div className="d-flex justify-content-center align-items-center h-100">
                            <div className="spinner-border" style={{color: props.leadColor}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div> }
                        {!loginInfo && loginErrorMsg && <div className='d-flex justify-content-center align-items-center h-100 px-3'>{loginErrorMsg}</div>}
                    </div>
                </div>
            </Modal>}
        </>
    )
}