export default function ExportIcon() {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <g clipPath="url(#se0u0z8i4a)" fill="#787878">
                    <path d="M11.412 1.054a.33.33 0 0 0-.564.234v1.763c-1.784.071-2.678.811-2.815.934a5.009 5.009 0 0 0-1.738 2.912 5.13 5.13 0 0 0-.059 1.677v.002l.003.015.07.404a.33.33 0 0 0 .609.113l.21-.35C7.774 7.682 8.58 6.97 9.522 6.64a3.47 3.47 0 0 1 1.326-.194v1.826a.33.33 0 0 0 .565.233l3.491-3.51a.33.33 0 0 0 0-.467l-3.492-3.474zm.097 6.417V6.149a.33.33 0 0 0-.278-.327 4.042 4.042 0 0 0-1.927.195c-.95.333-1.773.988-2.45 1.95a4.707 4.707 0 0 1 .09-.942A4.298 4.298 0 0 1 8.48 4.472c.023-.021.862-.779 2.696-.765h.002a.33.33 0 0 0 .331-.33V2.082l2.694 2.68-2.694 2.708z"/>
                    <path d="M13.79 10.687a.33.33 0 0 0-.331.33v1.397c0 .547-.444.99-.991.99H1.652a.992.992 0 0 1-.991-.99V4.542c0-.547.444-.99.991-.991h2.285a.33.33 0 1 0 0-.66H1.652A1.654 1.654 0 0 0 0 4.541v7.872c.001.912.74 1.65 1.652 1.652h10.816a1.654 1.654 0 0 0 1.652-1.652v-1.397a.33.33 0 0 0-.33-.33z"/>
                </g>
                <defs>
                    <clipPath id="se0u0z8i4a">
                        <path fill="#fff" d="M0 0h15v15H0z"/>
                    </clipPath>
                </defs>
            </svg>
}