import React from "react";
import './OutOfArcBtn.css'
import OutOfArchIcon from "../../assets/svg/outOfArch";

export default function OutOfArcBtn(props) {

  const projectOutFromArchive = () => {
    console.log('send Project back to projects', props.rowId)
  }

    return  <div className="EditArcBtns OutOfArcBtn d-flex justify-content-end container-fluid">
                <div className="flex-shrink-0 row row_wide">
                    <div className="col-12">
                      <div onClick={projectOutFromArchive} className="d-flex archive_btn flex-column justify-content-center align-items-center">
                          <OutOfArchIcon/>
                          <span className="text-color-selected">הוצאה מהארכיון</span>
                        </div>
                    </div>       
                </div>
            </div>  
}