import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Projects from './pages/Projects/Projects';
import ActionForm from './pages/ActionForm/ActionForm';
import Footer from './components/Footer/Footer';
import { getMainPageData } from './utils/apiUtils';
import { getTableRowsReady, sendToLoginPage } from './utils/externalFunc';
import Login from './pages/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import { setFleg, setLocationIdent } from './store/YamatReducer';
import checkConnected from './pages/Login/checkConnected';
import axios from 'axios';
import ErrorPage from './pages/404/404';
import { isDesktop } from 'react-device-detect';
import MobileAlert from './pages/MobileAlert/MobileAlert';

function App() {

  const store = useSelector(state => state.reducer)
  const [token, setToken] = useState(checkConnected());
  const [mainDataObj, setMainDataOb] = useState(false)
  const [projectTableRows, setProjectTableRows] = useState(false)
  const [filterValues, setFilterValues] = useState({})
  const leadColor = "#b60072"
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let locationIdent = location.pathname.split("/")

  let finalMount = useRef(true);
  useEffect(() => {
      if(finalMount.current){
        finalMount.current = false 
        if(locationIdent.length > 0 && isDesktop){
            dispatch(setLocationIdent(locationIdent[1]))
        }     
      }
  },[])

  useEffect(() => {
    // Check for existing token
    if(store.locationIdent && !location.pathname.includes( `/login`) && isDesktop){
      if(token){
        manageToken(token);
      }else{
          // If not in login page -> redirect to login page
          sendToLoginPage(store.locationIdent)
      }
    }
    
  }, [store.locationIdent])

  // Set token and continue to app
   const manageToken = (token) => {
    setToken(token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token 
    store.locationIdent && !location.pathname.includes( `/404`) && getMainObjData()
  }
  
  const getMainObjData = () => {
      dispatch(setFleg({key: 'initial', value: true}))
      getMainPageData(store.locationIdent).then(res => {
        setMainDataOb(res.data)
        dispatch(setFleg({key: 'connected', value: true}))
        if(res.data.ProjectsTable?.TableRows?.length > 0) {
          let rowsObj = getTableRowsReady(res.data.ProjectsTable?.TableRows)
          setFilterValues(rowsObj.finalFilters)
          setProjectTableRows(rowsObj.finalRows)
        }
      })
      .catch(error => {
          console.log(error)
          console.log('wrong token or api error')
          if(!location.pathname.includes( `/login`) && error.response.status === 401){
              sendToLoginPage(store.locationIdent)
          }else{
              navigate("/404")
          }
          
      })
  }

  

  return (
    <div className="App f-reg">
      {mainDataObj && <Header mainDataObj={mainDataObj}/>}
      {isDesktop && <Routes>
                        <Route path='/:locationCode' element={<Navigate to={`/${locationIdent[1]}/login`} />} />
                        <Route path='/*' element={<ErrorPage/>} />
                        <Route path='/:locationCode/login' element={<Login leadColor={leadColor} mainDataObj={mainDataObj} manageToken={manageToken}/>} /> 
                        <Route path='/:locationCode/projects' element={<Projects leadColor={leadColor} projectTableRows={projectTableRows} mainDataObj={mainDataObj} filterValues={filterValues} getMainObjData={getMainObjData} type={mainDataObj.IsAllowAdd ? 'projects' : 'closed'}/>} />  
                        <Route path='/:locationCode/archive' element={<Projects leadColor={leadColor} projectTableRows={projectTableRows} mainDataObj={mainDataObj} filterValues={filterValues} type="archive"/>} />
                        <Route path='/:locationCode/action-form' element={<ActionForm getMainObjData={getMainObjData} leadColor={leadColor} mainDataObj={mainDataObj}/>} />
                        <Route path='/:locationCode/action-form/:id' element={<ActionForm getMainObjData={getMainObjData} leadColor={leadColor} mainDataObj={mainDataObj}/>} />
                    </Routes>}
      {mainDataObj && <Footer mainDataObj={mainDataObj}/>}
      {!isDesktop && <MobileAlert/>}
    </div>
  );
}

export default App;
