import React from 'react';
import "./MobileAlert.css"

export default function MobileAlert(){

   
    return (
        <>
            <div className='MobileAlert d-flex flex-column justify-content-center align-items-center text-white'>
                <p className='mb-0 text-center'>שמנו לב שאתם גולשים מהטלפון.</p> 
                <p className='mb-0 text-center'>לצערינו האתר אינו נתמך בגלישה</p>
                <p className='mb-0 text-center'>מהטלפון יש  לפתוח אותו מהמחשב.</p>
            </div>
        </>
    )
}