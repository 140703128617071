import { React, useEffect, useState } from "react";
import './ActionForm.css';
import { CustomSwitchBox, extractKeyName, fieldTypeDic, isJsonString, sortArray } from "../../utils/externalFunc";
import { useSelector } from "react-redux";

export default function ProjectSection(props){

    const store = useSelector(state => state.reducer)
    const [hoursArray, setHoursArray] = useState([])
    const [formErrors, setFormErrors] = useState({})
    const [mainArray, setMainArray] = useState(props.stepInfo.FormFields)

    useEffect(() => {
        buildHoursArray()
    }, [])

    const buildHoursArray = () => {
        let hours = [];
        for(let i = 0; i < 24; i++){
           let fullHour = i < 10 ? '0' + i + ':' + '00' : i + ':' + '00'
           let halfHour = i < 10 ? '0' + i + ':' + '30' : i + ':' + '30'
           let fullHourValue = fullHour + ':' + '00'
           let halfHourValue = halfHour + ':' + '00'
            hours.push({hour: fullHour, hourValue: fullHourValue})
            hours.push({hour: halfHour, hourValue: halfHourValue})
        }
        setHoursArray(hours)
    }

    const updateProjectDetailsObj = (value, path) => {   
        setFormErrors({...formErrors, [path]: ""}) 
        props.setFormState({...props.formValsLocal.ProjectDetails, [path]: value}, "ProjectDetails")     
    }

    useEffect(() => {
        setFormErrors(props.errorsObjReady)
    }, [props.errorsObjReady])

    useEffect(() => {
        let arr = sortArray(props.stepInfo.FormFields)
        setMainArray(arr)
    }, [props.stepInfo.FormFields])

    return  <>  <div className="ProjectSection my-5">
                    <h2 className="font-xl text-center primary_color f-bold">{props.stepInfo.Title}</h2>
                </div>
            <form action="" id="ProjectSection_form" className={`ProjectSection_form needs-validation ${props.validation === "start" ? "" : "was-validated"}`} noValidate> {/** was-validated */}

                <div className="row">
                    {
                        mainArray?.map((item) => {
                            
                            let formFieldDetails = item.FormFieldDetails && isJsonString(item.FormFieldDetails) ? JSON.parse(item.FormFieldDetails) : false
                            if(formFieldDetails){
                                let keyName = extractKeyName(formFieldDetails.object_path)                              
                                if(fieldTypeDic[item.FormFieldType] === 'checkbox' || keyName === "IsOrganization" || keyName === "OrganizationName"){ 
                                    return  <div key={item.FormFieldID}>
                                                <div className={`form-group d-flex justify-content-between align-items-center ${item.FormFieldID !== 8 ? 'mb-3' : ''}`}>
                                                    <label htmlFor={keyName}>
                                                        <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                                    </label>
                                                    {keyName !== "OrganizationName" &&
                                                        <CustomSwitchBox 
                                                            id={keyName} 
                                                            onChange={(e) => updateProjectDetailsObj(e.target.checked, keyName)}  
                                                            checked={props.formValsLocal?.ProjectDetails ? props.formValsLocal.ProjectDetails[keyName] : false}  />}                        
                                                </div>
                                                {keyName === "OrganizationName" &&
                                                <div className="input-group input-group-sm mb-3"> 
                                                    <input type="text" value={props.formValsLocal?.ProjectDetails[keyName] || ""} name={keyName} className={`form-control ${formErrors[keyName] ? "is-invalid" : ""}`} onChange={(e) => updateProjectDetailsObj(e.target.value, keyName)} id={keyName} required={item.IsRequired} disabled={props.formValsLocal?.ProjectDetails ? !props.formValsLocal?.ProjectDetails["IsOrganization"] : false}/>                          
                                                    <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                                </div>}
                                            </div>
                                }else if(fieldTypeDic[item.FormFieldType] === 'text' ||
                                        fieldTypeDic[item.FormFieldType] === 'tel' ||
                                        fieldTypeDic[item.FormFieldType] === 'email' ||
                                        fieldTypeDic[item.FormFieldType] === 'number'){
                                    return  <label key={item.FormFieldID} htmlFor={keyName} className="form-label mb-2">
                                                <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                                <div className="input-group input-group-sm mb-3"> 
                                                    <input 
                                                        type={fieldTypeDic[item.FormFieldType]} 
                                                        value={props.formValsLocal.ProjectDetails ? props.formValsLocal?.ProjectDetails[keyName] : ""} 
                                                        onChange={(e) => updateProjectDetailsObj(e.target.value, keyName)} 
                                                        className={`form-control ${formErrors[keyName] ? "is-invalid" : ""}`} 
                                                        id={keyName} required={item.IsRequired}
                                                        onWheel={(e) => e.target.blur()}
                                                    />                             
                                                    <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                                </div>
                                            </label>
                                }else if(fieldTypeDic[item.FormFieldType] === 'textarea'){
                                    return  <label key={item.FormFieldID} htmlFor={keyName} className="form-label mb-2">
                                                <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                                <div className="input-group input-group-sm mb-3"> 
                                                    <textarea
                                                    rows={6}
                                                    onChange={(e) => updateProjectDetailsObj(e.target.value, keyName)} 
                                                    value={props.formValsLocal.ProjectDetails ? props.formValsLocal?.ProjectDetails[keyName] : ""}
                                                    className={`form-control ${formErrors[keyName] ? "is-invalid" : ""}`} id={keyName} required={item.IsRequired}></textarea>                             
                                                    <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                                </div>
                                            </label>
                                }else if(fieldTypeDic[item.FormFieldType] === 'SelectSingle'){
                                    return  <label key={item.FormFieldID} htmlFor={keyName} className="form-label mb-2">
                                                <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                                <div className="input-group input-group-sm mb-3"> 
                                                    <select className={`form-select ${formErrors[keyName] ? "is-invalid" : ""}`} value={props.formValsLocal?.ProjectDetails ? props.formValsLocal?.ProjectDetails[keyName] : ""} onChange={(e) => updateProjectDetailsObj(Number(e.target.value), keyName)} id={keyName} required={item.IsRequired} aria-label="select example">
                                                        <option value="">בחרו מהרשימה</option>
                                                        {
                                                        formFieldDetails.select_values ? formFieldDetails.select_values.map((subItem) => {
                                                                return <option key={subItem.id} value={subItem.id}>{subItem.title}</option>
                                                            }) : null
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                                </div>
                                            </label>
                                }else if(fieldTypeDic[item.FormFieldType] === 'Time'){
                                    return  <div key={item.FormFieldID} className="col-6">
                                                <label htmlFor={keyName} className="form-label mb-2 w-100">
                                                    <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                                    <div className="input-group input-group-sm mb-3"> 
                                                        <select className={`form-select ${formErrors[keyName] ? "is-invalid" : ""}`} value={props.formValsLocal.ProjectDetails ? props.formValsLocal?.ProjectDetails[keyName] : ""} onChange={(e) => updateProjectDetailsObj(e.target.value, keyName)} id={keyName} required={item.IsRequired} aria-label="select example">
                                                            <option value="">בחרו מהרשימה</option>
                                                            {
                                                                hoursArray?.map((subItem, index) => {
                                                                    return <option key={'hours_' + index} value={subItem.hourValue}>{subItem.hour}</option>
                                                                })
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                                    </div>
                                                </label>
                                            </div>
                                }
                            }

                        })
                    }
                </div>

            </form></>
}