import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const CustomSwitchBox = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        left: 11,
        content: '"לא"',
        color: '#fff',
        fontSize: 13,
        top: 18,
      },
      '&:after': {
        content: '"כן"',
        right: 15,
        color: '#fff',
        fontSize: 13,
        top: '47%',
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff'
    },
    '& .MuiSwitch-switchBase:after': {
        content:'"לא"',
        color: 'gray',
        position: 'absolute',
        fontSize: 13,
        top: 10,
        right: 12.5
    },
    '& .MuiSwitch-switchBase.Mui-checked:after': {
        content:'"כן"',
        color: '#b60072',
        right: 14
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
        backgroundColor: '#b60072',
        opacity: 1
    }
  }));

  const fieldTypeDic = {
    1: 'checkbox',
    2: 'number',
    3: 'SelectSingle',
    4: 'SelectMultiple',
    5: 'text',
    6: 'ButtonGroup',
    7: 'ButtonSubmit',
    8: 'textarea',
    9: 'tel',
    10: 'email',
    11: 'Date',
    12: 'Time'
}

const getTableRowsReady = (obj) => {

  let finalRows = []
  let contactFilterValues = [""];
  let populationTypeFilterValues = [""];
  let hoursFilterValues = [""];
  let activityTypeFilterValues = [""];
  let participateNumberFilterValues = [""];

  obj?.map(item => {
      let row = {}
      item.GddColumnsData?.map(subItem => {

        if(subItem.GddColumnHeaderID === 1){ row.id = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 2){ row.projectName = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 3){ row.associationName = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 4){ row.creationDate = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 5){ row.contact = subItem.DisplayData; contactFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 6){ row.populationType = subItem.DisplayData; populationTypeFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 7){ row.hours = subItem.DisplayData; hoursFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 8){ row.activityType = subItem.DisplayData; activityTypeFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 9){ row.participateNumber = subItem.DisplayData; row.participateNumberTitle = subItem.Title; participateNumberFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 10){ row.madeForCompany = subItem.DataIds[0] }
        if(subItem.GddColumnHeaderID === 11){ row.options = subItem.DataIds[0] }

      })
      finalRows.push(row)
  })

  // console.log(finalRows)
  contactFilterValues = contactFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  populationTypeFilterValues = populationTypeFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  hoursFilterValues = hoursFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  activityTypeFilterValues = activityTypeFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  participateNumberFilterValues = participateNumberFilterValues.filter((value, index, array) => array.indexOf(value) === index);

  let finalFilters = {
    contact: contactFilterValues,
    populationType: populationTypeFilterValues,
    hours: hoursFilterValues,
    activityType: activityTypeFilterValues,
    participateNumber: participateNumberFilterValues
  }
  return {finalRows: finalRows, finalFilters: finalFilters}
}

const extractKeyName = (path) => {
  if(path && path.includes(".")){
      let pathArr = path.split(".")
      return pathArr[pathArr.length - 1]
  }else{
      return path
  }
}

const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const sortArray = (arr) => {
  arr.sort(function(a, b) {
    var keyA = a.Sort,
      keyB = b.Sort
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
  return arr
}

const stepsDic = {
  ContactSection_form: 0,
  ProjectSection_form: 1,
  PartnerSection_form: 2,
  SuccessFormSection: 3
}

const sendToLoginPage = (locationIdent) => {
  console.log('redirected to login page')
  window.location.href = `/${locationIdent}/login`
}

const muiProLicenseKey = "6576263f0a482cd2aa22962a213ce203Tz03Njk0MCxFPTE3MjkzMzQ5NzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
 
export { CustomSwitchBox, fieldTypeDic, getTableRowsReady, muiProLicenseKey, extractKeyName, isJsonString, sortArray, stepsDic, sendToLoginPage }