import React from "react";
import './EditArcBtns.css'
import MoveToArchIcon from "../../assets/svg/moveToArch";
import EditIcon from "../../assets/svg/edit";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function EditArcBtns(props) {

  const store = useSelector(state => state.reducer)
  const navigate = useNavigate()

  const editProject = () => {
    navigate(`/${store.locationIdent}/action-form/${props.rowId}`)
  }

  // const sendProjectToArchive = () => {
  //   console.log('send Project to archive', props.rowId)
  // }

    return  <div className="EditArcBtns d-flex justify-content-end container-fluid">
                <div className="flex-shrink-0 row row_wide">
                  
                    {/* <div className="col-8">
                      <div onClick={sendProjectToArchive} className="d-flex archive_btn flex-column justify-content-center align-items-center">                   
                          <MoveToArchIcon/>
                          <span className="text-color-selected">העברה לארכיון</span>
                        </div>
                    </div> */}
                    <div className="col-12"> {/* col-4 */}
                        <div onClick={editProject} className="d-flex edit_btn flex-column justify-content-center align-items-end"> 
                          <div className="d-flex flex-column justify-content-center align-items-center">                        
                            <EditIcon/>
                            <span className="text-color-selected">עריכה</span>
                          </div>
                        </div>
                    </div>
                  
                </div>
            </div>  
}