import React, { useEffect, useState } from "react";
import './FeedbackPopup.css'
import { Modal } from "react-bootstrap";
import CloseCircleIcon from "../../assets/svg/closeCircle";
import { CustomSwitchBox, extractKeyName } from "../../utils/externalFunc";
import FeedbackNumberInput from "./FeedbackNumberInput";
import FeedbackTextArea from "./FeedbackTextArea";
import { getFeedbackFinal, sendFeedbackFinal } from "../../utils/apiUtils";
import { useSelector } from "react-redux";

export default function FeedbackPopup(props) {

  const store = useSelector(state => state.reducer)
  const [activeCloseBtn, setActiveCloseBtn] = useState(false)
  const [popupTextObj, setPopupTextObj] = useState(false)
  const [doneProject, setDoneProject] = useState(false)
  const [formValidate, setFormValidate] = useState(false)
  //const [participates, setParticipates] = useState(0)
  //const [proccessDescription, setProccessDescription] = useState("")
  //const [budgetApprove, setBudgetApprove] = useState(0)
  const [formLoader, setFormLoader] = useState(false)
  const [loadLoader, setLoadLoader] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  const intialStateFeedbackPopup = {
    ProjectID: Number(props.feedbackRowId),
    IsDone: false,
    BudgetTotal: "",
    VolunteersNum: "",
    Description: ""
  }
  const [feedbackObj, setFeedbackObj] = useState(intialStateFeedbackPopup)

useEffect(() => {
    if(props.mainDataObj.PopupPages){
        setPopupTextObj(props.mainDataObj.PopupPages[props.feedbackPopupInfoIndex])
    }
}, [props.mainDataObj.PopupPages])

useEffect(() => {
    props.feedbackRowId && getFeedbackInfo(props.feedbackRowId)
}, [props.feedbackRowId])

const validateFeedback = () => {
    let form = document.getElementById("feedback_form")
    return form.checkValidity()
}

const updateFeedbackObj = (keyName, value) => {
    setFeedbackObj({...feedbackObj, [keyName]: keyName === "BudgetTotal" || keyName === "VolunteersNum" ? Number(value) : value})
}

const sendFeedback = () => {
    setFormValidate(true)
    if(validateFeedback() && feedbackObj.Description){
        setFormLoader(true)
        sendFeedbackFinal(feedbackObj, store.locationIdent).then(res => {
            res.data.success && setSuccessMsg(res.data.success_message) 
            res.data.success && setTimeout(() => {
                props.setModalShow(false)
                setSuccessMsg("")
                setFormValidate(false)
                props.getMainObjData()
            }, 2000)
        }).catch(error => console.log(error)).finally(() => setFormLoader(false))
    }
}

const getFeedbackInfo = (projectId) => {
    setLoadLoader(true)
    getFeedbackFinal(projectId).then(res => {
        setFeedbackObj(res.data)
    }).catch(error => setFeedbackObj(intialStateFeedbackPopup))
    .finally(x => setLoadLoader(false))
}

    return  popupTextObj && <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="feedbackPop"
                show={props.modalShow}
                onHide={() => {props.setModalShow(false); setFormValidate(false); setSuccessMsg("")}}
            >
                <div className='f-reg px-4 pb-4 pt-5 mb-3 relative feedback_popup'>
                    <div className='position-absolute close_circle_icon pointer' onClick={() => props.setModalShow(false)} onMouseLeave={() => setActiveCloseBtn(false)} onMouseEnter={() => setActiveCloseBtn(true)}><CloseCircleIcon active={activeCloseBtn} /></div>
                    <form id="feedback_form" className={`needs-validation ${formValidate ? "was-validated" : ''}`}>
                        <h1 className='text-center mt-3 mb-4 pb-2'>{popupTextObj?.Title}<div className="f-bold me-2">{props.feedbackTitle}</div></h1>
                    
                        {!loadLoader ? <div className="popInfo">
                            {
                                popupTextObj?.FormFields ? popupTextObj?.FormFields?.map(item => {
                                    let formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false
                                    let keyName = extractKeyName(formFieldDetails.object_path)
                                    if(item.FormFieldTypeDictionary?.Value === "Numeric"){
                                        return <FeedbackNumberInput key={item.FormFieldID} label={item.Title} value={feedbackObj[keyName]} keyName={keyName} fieldUpdate={updateFeedbackObj}/>
                                    }else if(item.FormFieldTypeDictionary?.Value === "TextBox"){
                                        return <FeedbackTextArea key={item.FormFieldID} label={item.Title} value={feedbackObj.Description} keyName={keyName} setProccessDescription={updateFeedbackObj}/>
                                    }else if(item.FormFieldTypeDictionary?.Value === "DisplaySwitchSingle"){
                                        return  <div key={item.FormFieldID}><label htmlFor="project_done">{item.Title}</label>
                                                    <CustomSwitchBox id="project_done" checked={feedbackObj.IsDone} onChange={(e) => {updateFeedbackObj(keyName, e.target.checked)}}/>
                                                </div>
                                    }
                                }) : <p className="text-center">מצטערים! חסר מידע למילוי המשוב...</p>
                            }

                            <div className={`d-flex justify-content-center mt-4 pt-3`}>
                                <button type='button' onClick={sendFeedback} className={`d-flex align-items-center f-bold btn btn-primary text-center ${!feedbackObj.BudgetTotal && !feedbackObj.VolunteersNum && !feedbackObj.Description ? 'disabled_btn' : ''}`}> 
                                    {popupTextObj.ButtonText}
                                    {formLoader && <span className="spinner-border spinner-border-sm ms-2 me-0" role="status" aria-hidden="true"></span>}
                                </button>
                            </div>
                            {successMsg && <div className={`d-flex justify-content-center mt-3 mb-0 text-success`}>
                                <p>{successMsg}</p>
                            </div>}
                        </div>:
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <div className="spinner-border" style={{color: props.leadColor}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>}
                    </form>
                </div>
            </Modal> 
}