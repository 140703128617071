export default function OutOfArchIcon() {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
                <g clipPath="url(#ero9vr2waa)" fill="#B60072">
                    <path d="M13.623 7.506H3.383c-1.025 0-1.844.82-1.844 1.843v8.808C1.54 19.18 2.36 20 3.382 20h10.241c1.025 0 1.844-.82 1.844-1.843V9.349c0-1.024-.82-1.843-1.844-1.843zm-2.191 4.977a.603.603 0 0 1-.615.615H6.188a.604.604 0 0 1-.614-.615v-1.556c0-.349.266-.615.614-.615.349 0 .615.266.615.615v.942h3.4v-.942c0-.349.266-.615.614-.615.349 0 .615.266.615.615v1.556zM13.621 5.253H3.381a.603.603 0 0 0-.615.614c0 .349.266.615.614.615h10.241a.604.604 0 0 0 .614-.615.604.604 0 0 0-.614-.614zM12.596 3H4.404a.603.603 0 0 0-.615.614c0 .349.266.615.615.615h8.192a.603.603 0 0 0 .615-.615.603.603 0 0 0-.615-.614z"/>
                </g>
                <circle cx="17.04" cy="5.038" r="3.743" fill="#fff"/>
                <path d="M17.036 0a5.043 5.043 0 0 1 5.038 5.038 5.043 5.043 0 0 1-5.038 5.037A5.043 5.043 0 0 1 12 5.038 5.043 5.043 0 0 1 17.036 0zm-2.292 7.264c.14.145.372.15.517.009l.009-.01 1.766-1.766 1.767 1.767a.365.365 0 1 0 .517-.517L17.553 4.98l1.767-1.766a.365.365 0 0 0-.517-.517l-1.767 1.767-1.766-1.767a.365.365 0 0 0-.517.517L16.52 4.98l-1.767 1.766a.365.365 0 0 0-.009.517z" fill="#B60072"/>
                <path d="M21.574 5.038A4.543 4.543 0 0 0 17.036.5 4.543 4.543 0 0 0 12.5 5.038a4.543 4.543 0 0 0 4.537 4.537 4.543 4.543 0 0 0 4.538-4.537z" stroke="#fff"/>
                <defs>
                    <clipPath id="ero9vr2waa">
                        <path fill="#fff" transform="translate(0 3)" d="M0 0h17v17H0z"/>
                    </clipPath>
                </defs>
            </svg>
}