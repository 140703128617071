import React, { useEffect, useMemo, useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import EditArcBtns from '../EditArcBtns/EditArcBtns';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro, heIL, useGridApiRef, GridToolbar, GridLogicOperator } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import './MainTable.css'
import OutOfArcBtn from '../OutOfArcBtn/OutOfArcBtn';
import { useDispatch, useSelector } from 'react-redux';
import { setFollowObj } from '../../store/YamatReducer';
import DotsIcon from '../../assets/svg/dots';
import { debounce } from '@mui/material';
import { muiProLicenseKey } from '../../utils/externalFunc';
import FeedbackPopup from '../FeedbackPopup/FeedbackPopup';

export default function MainTable(props) {

    LicenseInfo.setLicenseKey(muiProLicenseKey);
    const [locale, setLocale] = useState('heIL');
    const [followArray, setFollowArray] = useState({})
    const [showTooltip, setShowTooltip] = useState(false)
    const store = useSelector(state => state.reducer)
    const theme = useTheme()
    const dispatch = useDispatch()
    const themeWithLocale = useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );
    const srcCols = props.mainDataObj?.ProjectsTable?.TableColumnsHeader
    const label = { inputProps: { 'aria-label': 'Follow Checkbox' } };
    const [optionsMode, setOptionMode] = useState({})
    const apiRef = useGridApiRef();
    const [lastEditedRow, setLastEditedRow] = useState(false)
    const [filterModel, setFilterModel] = useState({items: []})
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false)
    const [feedbackRowId, setFeedbackRowId] = useState(false)
    const [feedbackTitle, setFeedbackTitle] = useState(false)

    useEffect(() => {
        Object.keys(props.tableRows).length !== 0 && init() 
    }, [props.tableRows])

    useEffect(() => {
        store.export !== 0 && lastEditedRow && apiRef.current.selectRow(lastEditedRow, false, true)
        store.export !== 0 && apiRef.current.exportDataAsCsv()
    }, [store.export])

    useEffect(() => {
        props.searchValue && updateSearchValue(props.searchValue)
        updateFilters(props.filters)
    }, [props.searchValue, props.filters])

    const init = () => {
        let rowsArray = {}
        props.tableRows && props.tableRows?.map((item) => {
            rowsArray[item.id] = false;
        })
        store.followObj && Object.keys(store.followObj).length > 0 ? setFollowArray(store.followObj) : setFollowArray(rowsArray)
        setOptionMode(rowsArray)
    }

    const handleOptionClick = (rowId) => {
        let obj = {}
        let keys = Object.keys(optionsMode)
        keys?.map((item) => {
            if(rowId){
                obj[item] = item === rowId
            }else{
                obj[item] = false
            }
        })
        // if(!(String(rowId) in obj)){
        //     obj[String(rowId)] = true
        // }
        setOptionMode(obj)
        rowId && apiRef.current.selectRow(rowId, true, true)
        setLastEditedRow(rowId)
    }

    const handleFollowClick = (rowId, follow) => {
        let obj = {...followArray}
        let keys = Object.keys(followArray)
        keys?.map((item) => {
            if(rowId && Number(item) == rowId){
                obj[item] = follow
            }else{
                if(!(String(rowId) in obj)){ obj[rowId] = follow }
            }
        })
        dispatch(setFollowObj(obj))
        setFollowArray(obj)
    }

    const updateFilters = (filters) => {
        let obj = {...filters}
        let items = []
        let keys = Object.keys(obj)
        keys?.map((item, index) => {
            if(obj[item]){
                let operator = item === "participateNumber" || item === "madeForCompany" ? '=' : 'contains'
                let filter = {
                    id: index,
                    field: item,
                    operator: operator,
                    value: obj[item],
                }
                items.push(filter)
            }
        })
        setFilterModel({
            items: items,
            logicOperator: GridLogicOperator.And
        })
    }

    /**
     *  when clicking outside of the table -> reset selection
     */
    const useClickOutside = (handler) => {
        const domNode = useRef();
        useEffect(() => {
            const eventHandler = (event) => {
                if (domNode.current && !domNode.current.contains(event.target)) {
                    handler();
                }
            }
            document.addEventListener("mousedown", eventHandler);
            return () => {
                document.removeEventListener('mousedown', eventHandler);
            }
        });
        return domNode;
    }

    const tableRow = useClickOutside(() => {
        lastEditedRow && apiRef.current.selectRow(lastEditedRow, false, true)
        handleOptionClick()
    })

    const updateSearchValue = useMemo(() => {
        return debounce((newValue) => {
            apiRef.current.setQuickFilterValues(
                newValue.split(" ").filter((word) => word !== "")
            );
        }, 200);
    }, [apiRef]);

    const columns = [
        { 
            field: 'follow', 
            headerName: 'עוקב', 
            headerClassName: 'info-label',
            width: 70, 
            overflow: 'hidden',
            type: 'boolean',
            renderCell: (params) => {
                return <Checkbox {...label} color="default" checked={store.followObj[params.row.id] ? true : false} onChange={(e) => handleFollowClick(params.row.id, e.target.checked)} icon={<CircleUnchecked />} size="small" checkedIcon={<CircleCheckedFilled />}/>
            },
            valueGetter: (params) => ({
                checked: store.followObj[params.row.id]
            }),
            sortComparator: (v1, v2) => {
                return (v1.checked === v2.checked) ? 0 : (v1.checked > v2.checked) ? 1 : -1;
            }
        },
        { 
            field: 'madeForCompany', 
            headerName: srcCols? srcCols[0]?.Title : "מתאים לחברה", 
            width: 70, 
            headerClassName: 'info-label',
            type: 'number',
        },
        { 
            field: 'id', 
            headerName: srcCols ? srcCols[1]?.Title : "מספר מזהה", 
            width: 70, 
            headerClassName: 'info-label',
            type: 'number',
            align: 'left'
        },
        { 
            field: 'projectName', 
            headerName: srcCols ? srcCols[2]?.Title : "שם הפרויקט", 
            flex: 0.13, 
            headerClassName: 'info-label', 
        },
        {
            field: 'associationName',
            headerName: srcCols ? srcCols[3]?.Title : "שם העמותה",
            flex: 0.13, 
            headerClassName: 'info-label',
        },
        {
            field: 'creationDate',
            headerName: srcCols ? srcCols[4]?.Title : "תאריך יצירה",
            flex: 0.1, 
            headerClassName: 'info-label',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'contact',
            headerName: srcCols ? srcCols[5]?.Title : "איש קשר",
            flex: 0.10, 
            headerClassName: 'info-label',
        },
        {
            field: 'populationType',
            headerName: srcCols ? srcCols[6]?.Title : "אוכלוסיה",
            flex: 0.11, 
            headerClassName: 'info-label',
        },
        {
            field: 'hours',
            headerName: srcCols ? srcCols[7]?.Title : "שעות פעילות",
            flex: 0.11, 
            headerClassName: 'info-label',
        },
        {
            field: 'activityType',
            headerName: srcCols ? srcCols[8]?.Title : "סוג הפעילות",
            flex: 0.12, 
            headerClassName: 'info-label',
        },
        {
            field: 'participateNumber',
            headerName: srcCols ? srcCols[9]?.Title : "מספר משתתפים",
            flex: 0.12, 
            type: 'number',
            headerAlign: 'left',
            headerClassName: 'info-label',
            renderCell: (params) => {
                return params.row.participateNumber ? 
                <div style={{transition: 'all 0.2s ease-in-out'}} className='relative text-color-regular w-100 h-100' onMouseEnter={() => setShowTooltip(params.row.id)} onMouseLeave={() => setShowTooltip(false)}>
                   <div>{params.row.participateNumber}</div>
                   <div className='position-absolute d-flex bg-body px-2 top-0 shadow-sm' style={{width: '90px', transition: 'all 0.2s ease-in-out', visibility: showTooltip !== params.row.id ? 'hidden' : 'visible'}}>
                        <span>{params.row.participateNumberTitle}</span>
                   </div>
                </div> : ''
            }
        },
        {
            field: 'options',
            headerName: props.closed ? "משוב" : "",
            flex: props.closed ? 0.07 : 0.05, 
            headerClassName: 'info-label',
            align: props.closed ? "left" : "right",
            renderCell: (params) => {
                return params.row.id ? 
                <div className='pointer relative'>
                    {optionsMode[params.row.id] && props.type === "projects" && <EditArcBtns rowId={params.row.id}/>}
                    {optionsMode[params.row.id] && props.type === "archive" && <OutOfArcBtn rowId={params.row.id}/>}
                    {!optionsMode[params.row.id] && props.type === "projects" && <div className='pe-2' onClick={() => handleOptionClick(params.row.id)}><DotsIcon/></div>}
                    {!optionsMode[params.row.id] && props.type === "closed" && 
                    <div className='text-color-regular f-reg' onClick={() => {
                        setShowFeedbackPopup(true); 
                        setFeedbackRowId(params.row.id); 
                        setFeedbackTitle(params.row.projectName)
                    }}>{params.row.options ? "בוצע" : "מילוי משוב"}</div>}
                </div> : ''
            }
        }
    ];  

    return (
        <div ref={tableRow} className="MainTable">
            <ThemeProvider theme={themeWithLocale}>
                <DataGridPro
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    filterModel={filterModel}
                    getRowHeight={() => 'auto'}
                    autoHeight {...props.tableRows}
                    sx={{ 
                        backgroundColor: '#fff', 
                        marginBottom: '20px',
                        '& .Mui-selected': {
                            backgroundColor: '#fff !important',
                        },
                        '& .Mui-selected .MuiDataGrid-cell:not(last-child) .MuiDataGrid-cellContent': {
                            color: '#d8d2d2'
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none'
                        },
                        '& .Mui-selected .MuiDataGrid-cell:first-of-type .MuiCheckbox-root svg': {
                            fill: '#d8d2d2'
                        }
                    }}
                    rows={props.tableRows ? props.tableRows : []} 
                    columns={columns}
                    pageSize={props.tableRows ? props.tableRows.length : 1}
                    rowsPerPageOptions={[props.tableRows ? props.tableRows.length : 1]}
                    localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                    disableRowSelectionOnClick
                    apiRef={apiRef}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
                    }
                    initialState={{
                        columns: {
                            ...columns,
                            columnVisibilityModel: {
                               madeForCompany: false
                            }
                        }
                    }}
                />
            </ThemeProvider>   
            <FeedbackPopup 
                getMainObjData={props.getMainObjData}
                setModalShow={setShowFeedbackPopup} 
                modalShow={showFeedbackPopup} 
                feedbackRowId={feedbackRowId} 
                feedbackTitle={feedbackTitle}
                mainDataObj={props.mainDataObj} 
                feedbackPopupInfoIndex={props.feedbackPopupInfoIndex}
                leadColor={props.leadColor}
            />
        </div>
    )
}
