import { createSlice } from '@reduxjs/toolkit';
import { getLocalStoragKey, setLocalStorage } from '../utils/YamatLocalStorage';

const localStoragefollowObj = getLocalStoragKey('ymt', 'followObj');
const localStorageformVals = getLocalStoragKey('ymt', 'formVals');
const localStorageformExtraVals = getLocalStoragKey('ymt', 'formExtraVals');
const localStorageflegs = getLocalStoragKey('ymt', 'flegs');
const localStorageUserDetails = getLocalStoragKey('ymt', 'userDetails');

const formInitialState = {
  "ProjectID": 0,
  "Contact": {
    "ContactID": 0,
    "LastName": "",
    "FirstName": "",
    "Phone": "",
    "Position": "",
    "Email": ""
  },
  "ProjectDetails": {
    "Title": "",
    "ProjcetTypeId": 0,
    "IsOrganization": false,
    "OrganizationName": "",
    "Location": "",
    "FieldId": 0,
    "Description": "",
    "ProjectValues": "",
    "Population": "",
    "VolunteersNum": "",
    "IsAllowPublish": false,
    "IsForCompany": false,
    "IsForPR": false,
    "Address": "",
    "HourFrom": "",
    "HourTo": "",
    "FormComment": ""
  },
  "ProjectBudgets": [
    {
      "ProjectBudgetID": 0,
      "BudgetDescription": "",
      "MunicipalityValueDescription": "",
      "MunicipalityAmount": "",
      "RuachtovaAmount": ""
    }
  ],
  "ProjectPartners": [
    {
      "ProjectPartnerId": 0,
      "ProjectPartnerTypeId": 0,
      "ProjectPartnerComment": ""
    }
  ]
}

export const YamatReducer = createSlice({
  name: 'reducer',
  initialState: {
    export: 0,
    followObj: localStoragefollowObj ? localStoragefollowObj : {},
    formVals: localStorageformVals ? localStorageformVals : formInitialState,
    formExtraVals: { isPartner: true, isBudget: true, activeStep: localStorageformExtraVals ? localStorageformExtraVals.activeStep : 0},
    fleg: localStorageflegs ? localStorageflegs : {welcomPopup: false, initial: false, connected: false},
    userDetails: localStorageUserDetails ? localStorageUserDetails : {isAdmin: false, phone: "", name: ""},
    locationIdent: ""
  },
  reducers: {
    clearFormVals: (state, action) => {
      state.formVals = formInitialState
      state.formExtraVals = {...state.formExtraVals, activeStep: 0}
      setLocalStorage('ymt', state)
    },
    exportMainTable: (state) => {
      state.export++
    },
    setFollowObj: (state, action) => {
      const { payload } = action
      state.followObj = payload
      setLocalStorage('ymt', state)
    },
    setFormVals: (state, action) => {
      const { payload } = action
      state.formVals = payload
      setLocalStorage('ymt', state)
    },
    setFormExtraVals: (state, action) => {
      const { payload } = action
      state.formExtraVals = payload
      setLocalStorage('ymt', state)
    },
    setFleg: (state, action) => {
      const { payload } = action
      state.fleg = {...state.fleg, [payload.key]: payload.value}
      setLocalStorage('ymt', state)
    },
    setUserDetails: (state, action) => {
      const { payload } = action
      state.userDetails = payload
      setLocalStorage('ymt', state)
    },
    setLocationIdent: (state, action) => {
      const { payload } = action
      state.locationIdent = payload
      setLocalStorage('ymt', state)
    }
  }
});

export const {  setProducts, exportMainTable, setFollowObj, setFormVals, setFleg, setFormExtraVals, clearFormVals,
                setUserDetails, setLocationIdent }  = YamatReducer.actions

export default YamatReducer.reducer;
