import { React, useState } from "react";
import './Projects';
import CloseCircleIcon from "../../assets/svg/closeCircle";
import Autocomplete from '@mui/material/Autocomplete';
import { Dialog, TextField } from "@mui/material";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { CustomSwitchBox } from "../../utils/externalFunc";

export default function Filters(props){
    
    const [activeCloseBtn, setActiveCloseBtn] = useState(false)
    
    const populationTypeValues = {
        options: props.filterValues.populationType || [""]
    }
    const contactValues = {
        options: props.filterValues.contact || [""],
    }
    const hoursValues = {
        options: props.filterValues.hours || [""],
    }
    const activityTypeValues = {
        options: props.filterValues.activityType || [""],
    }
    const participateNumberValues = {
        options: props.filterValues.participateNumber || [""],
    }

    const theme = createTheme({
        direction: 'rtl',
        typography: {
            fontFamily: 'almoni-neue-regular',
        },
        palette: {
            primary: {
              main: "#b60072",
            }
        }
    })
    // Create rtl cache
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    })

    return  <> <div className="filters_con position-absolute top-0 z-1 bg-body p-3 shadow-lg">
                    <div className='position-absolute close_circle_icon filt pointer' onClick={() => props.setFiltersShow(false)} onMouseLeave={() => setActiveCloseBtn(false)} onMouseEnter={() => setActiveCloseBtn(true)}><CloseCircleIcon active={activeCloseBtn} /></div>
                    <div className="input-group input-group-sm mb-4 pb-2 mt-5"> 
                        <input type='text' className="form-control search_input" value={props.searchValue} placeholder="חיפוש" onChange={(e) => props.searchInTable(e)}/>
                    </div>

                    <hr className='mb-2'/>
                    
                    <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme}>
                            <div dir="rtl">
                                <Autocomplete
                                    {...populationTypeValues}
                                    noOptionsText={"לא נמצאו תוצאות"}
                                    className="mb-3 mt-4 pt-2"
                                    id="clear-on-escape"
                                    clearOnEscape
                                    autoSelect
                                    size="small"
                                    filterOptions={populationTypeValues => populationTypeValues.filter(opt => opt)}
                                    value={props.filters.populationType}
                                    onChange={(e, newValue) => props.setFilters({...props.filters, populationType: newValue})}
                                    renderInput={(params) => (
                                    <TextField {...params} label="אוכלוסיה" />
                                    )}
                                />

                                <Autocomplete
                                    {...contactValues}
                                    noOptionsText={"לא נמצאו תוצאות"}
                                    className="mb-3 mt-4 pt-2"
                                    id="clear-on-escape"
                                    clearOnEscape
                                    autoSelect
                                    size="small"
                                    filterOptions={contactValues => contactValues.filter(opt => opt)}
                                    value={props.filters.contact}
                                    onChange={(e, newValue) => props.setFilters({...props.filters, contact: newValue})}
                                    renderInput={(params) => (
                                    <TextField {...params} label="איש קשר" />
                                    )}
                                />

                                <Autocomplete
                                    {...hoursValues}
                                    noOptionsText={"לא נמצאו תוצאות"}
                                    className="mb-3 mt-4 pt-2"
                                    id="clear-on-escape"
                                    clearOnEscape
                                    autoSelect
                                    size="small"
                                    filterOptions={hoursValues => hoursValues.filter(opt => opt)}
                                    value={props.filters.hours}
                                    onChange={(e, newValue) => props.setFilters({...props.filters, hours: newValue})}
                                    renderInput={(params) => (
                                    <TextField {...params} label="שעות פעילות" />
                                    )}
                                />

                                <Autocomplete
                                    {...activityTypeValues}
                                    noOptionsText={"לא נמצאו תוצאות"}
                                    className="mb-3 mt-4 pt-2"
                                    id="clear-on-escape"
                                    clearOnEscape
                                    autoSelect
                                    size="small"
                                    filterOptions={activityTypeValues => activityTypeValues.filter(opt => opt)}
                                    value={props.filters.activityType}
                                    onChange={(e, newValue) => props.setFilters({...props.filters, activityType: newValue})}
                                    renderInput={(params) => (
                                    <TextField {...params} label="תחום פעילות" />
                                    )}
                                />

                                <Autocomplete
                                    {...participateNumberValues}
                                    noOptionsText={"לא נמצאו תוצאות"}
                                    className="mb-3 mt-4 pt-2"
                                    id="clear-on-escape"
                                    clearOnEscape
                                    autoSelect
                                    size="small"
                                    filterOptions={participateNumberValues => participateNumberValues.filter(opt => opt)}
                                    value={props.filters.participateNumber}
                                    onChange={(e, newValue) => props.setFilters({...props.filters, participateNumber: newValue})}
                                    renderInput={(params) => (
                                    <TextField {...params} label="כמות משתתפים" />
                                    )}
                                />  

                                <div className={`form-group d-flex justify-content-between align-items-center filter_switch`}>
                                    <label htmlFor="madeForCompany">מתאים לחברה</label>                                  
                                    <CustomSwitchBox 
                                        id="madeForCompany"
                                        onChange={(e, newValue) => props.setFilters({...props.filters, madeForCompany: newValue ? "1" : ""})}  
                                        checked={props.filters.madeForCompany === '' ? false : true}  
                                    />                       
                                </div>    
                            </div>
                        </ThemeProvider>
                    </CacheProvider>
                    

                    {/* <label className="form-label d-block mb-2">אוכלוסיה
                            <div className="input-group input-group-sm mb-3"> 
                                <select className="form-select" value={props.filters.populationType} onChange={(e) => props.setFilters({...props.filters, populationType: e.target.value})} aria-label="select populationType">
                                    <option value="">הכל</option>
                                    {
                                        props.filterValues.populationType?.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                    </label> */}
                    
                    {/* <label className="form-label d-block mb-2">איש קשר
                            <div className="input-group input-group-sm mb-3"> 
                                <select className="form-select" value={props.filters.contact} onChange={(e) => props.setFilters({...props.filters, contact: e.target.value})} aria-label="select contact">
                                    <option value="">הכל</option>
                                    {
                                        props.filterValues.contact?.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                    </label> */}

                    {/* <label className="form-label d-block mb-2">שעות פעילות
                            <div className="input-group input-group-sm mb-3"> 
                                <select className="form-select" value={props.filters.hours} onChange={(e) => props.setFilters({...props.filters, hours: e.target.value})} aria-label="select hours">
                                    <option value="">הכל</option>
                                    {
                                        props.filterValues.hours?.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                    </label> */}

                    {/* <label className="form-label d-block mb-2">תחום פעילות
                            <div className="input-group input-group-sm mb-3"> 
                                <select className="form-select" value={props.filters.activityType} onChange={(e) => props.setFilters({...props.filters, activityType: e.target.value})} aria-label="select activityType">
                                    <option value="">הכל</option>
                                    {
                                        props.filterValues.activityType?.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                    </label> */}

                    {/* <label className="form-label d-block mb-2">כמות משתתפים
                            <div className="input-group input-group-sm mb-3"> 
                                <select className="form-select" value={props.filters.participateNumber} onChange={(e) => props.setFilters({...props.filters, participateNumber: e.target.value})} aria-label="select participateNumber">
                                    <option value="">הכל</option>
                                    {
                                        props.filterValues.participateNumber?.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                    </label> */}

                    <div className='d-flex justify-content-end primary_color mt-2'><a className="pointer" onClick={() => {props.setFilters(props.initialFiltersState); props.setSearchValue("") }}>נקה פילטרים</a></div>
                </div> 
            </>
}