import React, { useEffect, useState } from 'react';
import MainTable from '../../components/MainTable/MainTable';
import "./Projects.css"
import FiltersIcon from '../../assets/svg/filters';
import PlusIcon from '../../assets/svg/plus';
import SearchIcon from '../../assets/svg/search';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CloseCircleIcon from '../../assets/svg/closeCircle';
import { useDispatch, useSelector } from 'react-redux';
import { clearFormVals, setFleg } from '../../store/YamatReducer';
import Filters from './Filters';

export default function Projects(props){

    const initialFiltersState = {
        populationType: "",
        contact: "",
        hours: "", 
        activityType: "", 
        participateNumber: "",
        madeForCompany: "" 
    }
    const [activeCloseBtn, setActiveCloseBtn] = useState(false)
    const store = useSelector(state => state.reducer)
    const [tableRows, setTableRows] = useState(props.projectTableRows)
    const [modalShow, setModalShow] = useState(false)
    const [filtersShow, setFiltersShow] = useState(false)
    const [filters, setFilters] = useState(initialFiltersState)
    const [searchValue, setSearchValue] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const closed = props.type === "closed"
    const popupTextObj = closed ? "MunicipalitiesMainPageClosed" : "MunicipalitiesMainPageIntroduction"
    const popupInfoIndex = props.mainDataObj?.PopupPages?.findIndex(x => x.PopupPageTypeDictionary.Value === popupTextObj)
    const feedbackPopupInfoIndex = props.mainDataObj?.PopupPages?.findIndex(x => x.PopupPageTypeDictionary.Value === "MunicipalitiesProjectFeedback") 

    
    useEffect(() => {  
        if(typeof(props.projectTableRows) !== 'undefined'){
            setTableRows(props.projectTableRows)
        }
    }, [props.type, props.projectTableRows])

    useEffect(() => {
        if(!store.fleg.welcomPopup && popupInfoIndex !== -1){
            setModalShow(true)
            dispatch(setFleg({key: 'welcomPopup', value: true}))
        }
        
    }, [ store.fleg.welcomPopup, tableRows])

    const searchInTable = (e) => {
        setSearchValue(e.target.value)
    }

    return (
        <>
            <div className={`container-fluid Projects ${closed ? "closed" : ""}`}>
                {(props.type === "projects" || closed) && props.mainDataObj && <div className='title_con'>
                    <h1 className='font-xxl mb-0 title mb-3'>{props.mainDataObj.Title}</h1>
                </div>}
                {props.mainDataObj && <div className={`row mb-3 ${closed ? "flex-column-reverse" : ""}`}>
                    <div className={`col-6 ${closed ? 'mt-4 pt-3' : ''} align-items-end`}>
                        {props.type !== 'archive' && <div className="main_table_buttons align-items-end d-flex h-100">
                                {props.type === 'projects' && <div className=''>
                                    <button type="button" onClick={() => {dispatch(clearFormVals()); navigate(`/${store.locationIdent}/action-form`)}} className="main_page_btn btn btn-primary d-flex justify-content-between align-items-center px-2 me-1 ms-0">
                                        <span className='me-1 font-m'>הוספת פרויקט</span>
                                        <span className='btn_icon'><PlusIcon/></span>
                                    </button>
                                </div>}
                            <div className='relative'>
                                <button type="button" onClick={() => setFiltersShow(true)} className="main_page_btn btn btn-dark me-1 ms-0 d-flex justify-content-between align-items-center px-2">
                                    <span className='me-1 font-m'>פילטרים</span>
                                    <span className=''><FiltersIcon/></span>
                                </button>
                                {filtersShow && <Filters 
                                    setFiltersShow={setFiltersShow} 
                                    searchValue={searchValue}
                                    searchInTable={searchInTable}
                                    setFilters={setFilters}
                                    filters={filters}
                                    filterValues={props.filterValues}
                                    setSearchValue={setSearchValue}
                                    initialFiltersState={initialFiltersState}/>}
                            </div>
                            <div className="input-group search_input">
                                <input type="text" className="form-control font-m" value={searchValue} onChange={(e) => searchInTable(e)} placeholder="חיפוש" aria-label="search" aria-describedby="basic-addon1"/>
                                <span className="input-group-text " id="basic-addon1"><SearchIcon/></span>
                            </div>
                        </div>}
                    </div>
                    <div className={`col-${closed ? '12' : '6'} d-flex justify-content-end`}>
                        {
                            props.mainDataObj?.DataTiles?.map((item, index) => {
                                return  <div key={index} className="databox font-l d-flex p-3 flex-column justify-content-between ms-1 me-0">
                                            <p className="mb-0 lh-sm">{item.Label}</p>
                                            <p className="primary_color mb-0 font-xxxl lh-1">{item.InfoData}</p>
                                        </div>
                            })
                        }
                    </div>                 
                </div>}
                {props.mainDataObj ? 
                    <MainTable 
                        closed={closed} 
                        type={props.type} 
                        mainDataObj={props.mainDataObj} 
                        getMainObjData={props.getMainObjData}
                        tableRows={tableRows} 
                        searchValue={searchValue} 
                        filters={filters} 
                        feedbackPopupInfoIndex={feedbackPopupInfoIndex}
                        leadColor={props.leadColor}
                    />:
                    <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
                        <div className="spinner-border" style={{color: props.leadColor}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>                  
                }
            </div> 
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                {props.mainDataObj.PopupPages && <div className='f-reg px-5 py-5 relative'>
                    <div className='position-absolute close_circle_icon pointer' onClick={() => setModalShow(false)} onMouseLeave={() => setActiveCloseBtn(false)} onMouseEnter={() => setActiveCloseBtn(true)}><CloseCircleIcon active={activeCloseBtn} /></div>
                    <h3 className='primary_color f-bold mt-3 mb-4' dangerouslySetInnerHTML={{__html: props.mainDataObj?.PopupPages[popupInfoIndex].Title}}></h3>
                    <p className='bb' dangerouslySetInnerHTML={{__html: props.mainDataObj?.PopupPages[popupInfoIndex].RawHTML}}></p>
                    <div className='d-flex justify-content-center mt-4'>
                        <button autoFocus onClick={() => setModalShow(false)} className='f-bold btn btn-primary text-center' dangerouslySetInnerHTML={{__html: props.mainDataObj?.PopupPages[popupInfoIndex].ButtonText}}></button>
                    </div>
                </div>}
            </Modal>
            
        </>
    )
}