import { React, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearFormVals, setFormExtraVals } from "../../store/YamatReducer";

export default function SuccessFormSection(props){ 

    const store = useSelector(state => state.reducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [progress, setProgress] = useState(10)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
              if (oldProgress === 100) {
                clearInterval(timer)
                dispatch(setFormExtraVals({...store.formExtraVals, activeStep: 0}))
                dispatch(clearFormVals())
                navigate(`/${store.locationIdent}/projects`)
              }
              const diff = Math.random() * 10;
              return Math.min(oldProgress + diff, 100);
            });
          }, 150);
      
          return () => {
            clearInterval(timer);
          };
    }, []);
      
    return  <>  
                <div className="rounded-0 p-5 mb-0 mt-5 d-flex flex-column align-items-center justify-content-center" role="alert">
                    <h3 className="primary_color mb-1 mt-5">עבודה טובה</h3>
                    <h3 className="mb-0 text-black mb-4">{props.successMsg || "הטופס נשלח בהצלחה"}</h3>
                    <Link to={`/${store.locationIdent}/projects`} className="mb-0 text-black">בחזרה לעמוד פרויקטים</Link>
                    {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress style={{color: '#333 !important'}} variant="determinate" value={progress} />
                    </Box> */}
                </div>
            </>
}