import React from 'react';
import "./404.css"

export default function ErrorPage(){

   
    return (
        <>
            <div className='main_con_404 d-flex flex-column justify-content-center align-items-center'>
                <h1>404</h1>
                <p className='fw-bold'>אופס... העמוד לא נמצא</p>
            </div>
        </>
    )
}