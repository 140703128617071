import axios from "axios"
import checkConnected from "../pages/Login/checkConnected";

const apiUrl = "https://prod-ruachtova-gdd.azurewebsites.net"
//const apiUrl = "https://dev-ruachtova-gdd.azurewebsites.net"
//const apiUrl = ""

//const locationIdent = "QRzjf385RMyDivLbw07xeEUcG9C75bOPAy4Z0wOmaeT7MPGcUOKRWBFJ0HvNoNY6"
                      //"VnDRFcTytmuAHGuBGhA3UBEuhGrz7Mq0Vf6ob6FAT9apTfYRY9g_Cni-LRn-eH_t"
                      

const URLactionForm = `${apiUrl}/api/MunicipalitiesProjectForm/Get/`
const URLloginPage = `${apiUrl}/api/Municipalities/GetLoginPage/`
const URLmainPageData = `${apiUrl}/api/Municipalities/GetMainPage/`
const URLformValidation = `${apiUrl}/api/MunicipalitiesProject/Validate/`
const URLaddProject = `${apiUrl}/api/MunicipalitiesProject/Post/` 
const URLeditProject = `${apiUrl}/api/MunicipalitiesProject/Put/` 
const URLgetProject = `${apiUrl}/api/MunicipalitiesProject/Get/`
const URLsendFeedback = `${apiUrl}/api/MunicipalitiesProject/AddProjectFeedback/`
const URLgetFeedback = `${apiUrl}/api/MunicipalitiesProject/GetProjectFeedback/`
const URLsendCode = `${apiUrl}/api/MunicipalitiesAccount/SendCode`
const URLlogin = `${apiUrl}/token`

const getLoginForm = (locationIdent) => {
    return axios.get(URLloginPage + locationIdent)
}

const getMunicForm = (locationIdent) => {
    return axios.get(URLactionForm + locationIdent, { headers: { Authorization: `Bearer ${checkConnected()}` }})
}

const getMainPageData = (locationIdent) => {
    return axios.get(URLmainPageData + locationIdent)
}

const getProjectInfo = (projectId, locationIdent) => {
    return axios.get(`${URLgetProject + locationIdent}/${projectId}`, { headers: { Authorization: `Bearer ${checkConnected()}` }})
}

const sendPhoneNumber = (phoneNumber, locationIdent) => {
    return axios.post(URLsendCode, {
        "PublicKeyEncrypted": locationIdent,
        "Phone": phoneNumber,
        "Pin": ""
    })
}

const authenticateCode = (phoneNumber, code) => {
    let data = {
        "grant_type": 'password',
        "username": phoneNumber,
        "pin": code
    }
    return axios.post(URLlogin, data, {headers: {'content-type': 'application/x-www-form-urlencoded'}})
}

const apiFormValidation = (store) => {
    return axios.post(URLformValidation + store.locationIdent, store.formVals)
}

const addProject = (municipalityProject, locationIdent) => {
    return axios.post(URLaddProject + locationIdent, municipalityProject)
}

const editProject = (municipalityProject, locationIdent) => {
    return axios.put(URLeditProject + locationIdent, municipalityProject)
}

const sendFeedbackFinal = (feedbackObj, locationIdent) => {
    return axios.post(URLsendFeedback + locationIdent, feedbackObj)
}

const getFeedbackFinal = (projectId) => {
    return axios.get(`${URLgetFeedback}/${projectId}`)
}
 
export { getLoginForm, getMunicForm, getMainPageData, sendPhoneNumber, authenticateCode, apiFormValidation, addProject, editProject,
         getProjectInfo, sendFeedbackFinal, getFeedbackFinal}